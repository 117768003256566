.offer, .radio{
  .header{
  background-image: linear-gradient(#15153c, transparent);
}
}
.content-page {
  padding-top: 10px;
  .img-part{
    img{
      width: 100px;
      margin-bottom: 10px;
    }
  }
  .name-logo {
    margin: 30px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title-page {
      font-size: 24px;
      text-transform: uppercase;
      color: #FF840C;
      margin-bottom: 0;
      font-weight: 400;
      @media screen and (max-width: 600px) {
        font-size: 24px;
      }
    }
    .nome-radio{
      color: #FF840C;
      text-transform: uppercase;
      font-size: 32px;
      margin-bottom: 0;
    }
    .back-button {
      border: 1px solid #b1b3b4;
      color: #b1b3b4;
      border-radius: 24px;
      padding: 5px 35px;
      height: 50px;
      text-transform: uppercase;
      font-weight: normal;
      @media screen and (max-width: 600px) {
        padding: 2px 25px;
        font-size: 12px;
      }
    }
  }
  img.logo {
    width: auto;
    height: auto;
    margin: 5px 0;
    @media screen and (max-width: 600px) {
      margin-bottom: 30px;
      img {
        height: 56px;
      }
    }
  }
  p.text {
    font-size: 19px;
    margin-bottom: 10px;
    @media screen and (max-width: 600px) {
      font-size: 18px;
      margin-bottom: 40px;
    }
  }
  .icon-box {
    @media screen and (max-width: 600px) {
      align-items: baseline;
    }
    .piksel-wysiwyg-litem {
      flex: 0 0 25%;
      align-items: baseline;
      @media screen and (max-width: 600px) {
        flex: 0 0 50%;
        align-items: center;
        justify-content: center;
        text-align: center;
      }
      figure {
        margin-bottom: 10px;
        img {
          width: 80px;
          height: 80px;
          padding: 15px;
        }
      }
      .subtitle {
        font-size: 34px;
        font-weight: 500;
        margin-bottom: 10px;
      }
      .sub-subtitle {
        font-size: 16px;
        padding-right: 10px;
        text-align: left;
        @media screen and (max-width: 600px) {
          text-align: center;
          padding-right: 0;
        }
      }
      .digitalia-img {
        width: 80px;
        height: auto;
        margin-bottom: 15px;
      }
    }
  }
  .dwnl-link {
    text-transform: uppercase;
    text-decoration: underline;
    font-size: 16px;
    color: #000;
    @media screen and (max-width: 600px) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  
}
.radio{
  .orange-section{
    background-color: transparent;
    &.row-flex{
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media screen and (max-width: 1000px) {
        flex-direction: column;
        align-items: normal;
      }
      .text{
          flex: 0 0 20%;
          text-align: right;
          @media screen and (max-width: 1000px) {
            flex: 100%;
            text-align: center;
          }
          a{
              color: grey;
              border-radius: 20px;
          }
      }
  }
  }
  
}
.video-overlay{    
  position: fixed;
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.9);
  iframe{
      width: calc(100% - 15rem);
  height: calc(100% - 15rem);
  @media screen and (max-width: 800px) {
    width: calc(100% - 1rem);
    height: fit-content;
  }
}
.box-close{
  position: absolute;
  right: 2rem;
  top: 2rem;
  cursor: pointer;
  color: #000;
  background-color: #fff;
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 999px;
  font-size: 20px;
  user-select: none;
  transform: scale(1);
  transition: 0.25s all ease;
  &:hover{
      transform: scale(1.2);
  }
}
}
