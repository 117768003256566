strong{
    font-weight: bold !important;
}
.orange-section{
        background-color: #ff9228;
       
    .text-section{
        color: #fff;
    }
    .title-section{
        color: #fff;
    }
    .dot-list{
        display: flex;
        align-items: center;
        @media screen and (max-width: 600px) {
            flex-wrap: wrap;
          }
        .list{
            flex: 1;
            position: relative;
            margin-left: -2px;
            @media screen and (max-width: 800px) {
                flex: 100%;
              }
            p.number{
                text-align: center;
                color: #fff;
                font-weight: bold;
                font-size: 21px;
            }
            &.start{
                margin-bottom: 0;
                position: relative;
                max-width: 250px;
                @media screen and (max-width: 900px) {
                    max-width: 150px;
                    margin: 0 auto;
                    height: 160px;
                  }
                &::before{
                    display: none;
                }
                &::after{
                    display: none;
                }
            }
            .line{
                position: relative;
                display: flex;
                justify-content: center;
                height: 60px;
                margin-top: 30px;
                @media screen and (max-width: 900px) {
                    margin-top: 0;
                    height: 50px;
                  }
                &::after{
                    content: "";
                    width: 15px;
                    height: 15px;
                    background-color: #fff;
                    border-radius: 100%;
                    position: absolute;
                    left: 50%;
                    bottom: -4px;
                    transform: translate(-50%, 0);
                    @media screen and (min-width: 600px) and (max-width: 1000px) {
                        bottom: -8px !important;
                      }
                }
                &::before{
                    content: "";
                    width: 100%;
                    height: 2px;
                    position: absolute;
                    background-color: #fff;
                    border-radius: 5px;
                    left: 49%;
                    bottom: 0;
                    transform: translate(-50%, -50%);
                    @media screen and (min-width: 600px) and (max-width: 1000px) {
                        height: 2px;
                        bottom: -3px !important;
                      }
                    
                }
            }
            p.descrizione{
                color: #fff;
                text-align: center;
                margin-top: 30px;
                font-size: 20px;
                @media screen and (max-width: 600px) {
                    margin-top: 10px;
                }
                &.absolute{
                    position: absolute;
                    bottom: 38px;
                    left: 46%;
                    transform: translate(-50%, -50%);
                    @media screen and (min-width: 600px) and (max-width: 1100px) {
                        white-space: nowrap;
                        bottom: -12px;
                      }
                      @media screen and (min-width: 1000px) and (max-width: 1200px) {
                        bottom: -6px;
                      }
                      @media screen and (max-width: 600px) {
                        position: relative;
                        bottom: 47px;
                        left: 0;
                        transform: none;
                        margin-top: 0;
                      }
                }
            }
        }
        &.dark{
            flex: 0 0 80%;
            @media screen and (max-width: 900px) {
                flex: 100%;
              }
              .list{
                margin-left: -5px;
                &.start{
                    margin: 0 auto;
                }
              }
           p.descrizione{
            color: #ff840c;
            @media screen and (max-width: 900px) {
                margin-top: 10px;
              }
            &.absolute{
                white-space: nowrap;
                bottom: 13px;
                @media screen and (max-width: 600px) {
                    bottom: 43px;
                  }
            }
           } 
           .line{
            @media screen and (max-width: 600px) {
                height: 50px;
              }
            &::after{
                background-color: #ff840c;
                bottom: -4px;
            }
            &::before{
                background-color: #ff840c;
                bottom: 0.5px;
            }
        }
        p.number{
            color: #ff840c;

        }
        .filter-orange{
            filter: invert(31%) sepia(38%) saturate(7048%) hue-rotate(353deg) brightness(103%) contrast(103%);
        }
        }
    }
  
        .list-text{
            display: flex;
            span{
                flex: 1;
                text-align: center;
                color: #fff;
                font-size: 18px;
                font-weight: 500;
            }
        }
}
.slick-slide{
    a{
        display: block !important;
    }
}