@media (min-width: 1200px) {
  .container {
    max-width: 1024px !important;
  }
}

.header.scrolled {
  background-image: none !important;
  background-color: #FF840C;
}
.header.full-height {
  height: 100vh;
  background-color: rgba(255, 132, 12, 0.95);
  background-image: none !important;
}
.header .navbar {
  padding: 25px !important;
  max-width: 100% !important;
}
@media (min-width: 1074px) {
  .header .navbar {
    padding: 25px 0 !important;
    max-width: 1024px !important;
  }
}
@media (min-width: 1221px) {
  .header .navbar {
    flex-flow: row;
  }
  .header .navbar .collapse {
    display: flex !important;
  }
}
@media (max-width: 1220px) {
  .header .navbar .logo-wrap {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}
.header .navbar .show .navbar-nav {
  display: block;
}
.header .navbar .navbar-nav {
  width: 100%;
  justify-content: space-evenly;
  flex-direction: column !important;
}
@media (max-width: 1220px) {
  .header .navbar .navbar-nav {
    margin-top: 20px;
  }
}
@media (min-width: 1221px) {
  .header .navbar .navbar-nav {
    flex-direction: row !important;
    display: flex !important;
  }
}
.header .navbar .navbar-nav .nav-item.active .nav-link {
  font-weight: bold;
}
.header .navbar .navbar-nav .nav-item a.nav-link {
  color: #fff;
  text-transform: uppercase;
  font-size: 17px;
}
@media (max-width: 1220px) {
  .header .navbar .navbar-brand {
    width: 190px;
  }
}
.header .navbar .navbar-brand img {
  max-width: 75%;
}
@media (min-width: 1221px) {
  .header .navbar .navbar-toggler {
    display: none !important;
  }
}
@media (max-width: 1220px) {
  .header .navbar .navbar-toggler {
    display: block !important;
  }
}

.generic_page {
  padding-top: 116px;
}
.generic_page .header {
  background-image: none !important;
  background-color: #1769b2;
}

body {
  font-family: "Montserrat", sans-serif !important;
}
body.menu-opened {
  overflow: hidden;
}

section {
  padding: 40px 0 30px 0;
}
section.slider-section {
  padding: 0;
}
section.bg-grey {
  background-color: #f3f3f3;
}
section .title-section {
  color: #ff840c;
  font-size: 34px;
  text-transform: uppercase;
  font-weight: 500;
  text-align: center;
  margin-bottom: 20px;
}
@media (max-width: 600px) {
  section .title-section {
    font-size: 23px;
  }
}
section .text-section {
  font-size: 19px;
  font-weight: 300;
  color: #000;
  text-align: center;
  margin-bottom: 30px;
  padding: 0 20px;
}
@media (max-width: 600px) {
  section .text-section {
    padding: 0;
    font-size: 18px;
  }
}
section .icon-box {
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 600px) {
  section .icon-box {
    justify-content: space-around;
  }
}
section .icon-box > div {
  flex: 0 0 33.3%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  line-height: 1;
}
@media (max-width: 600px) {
  section .icon-box > div {
    flex: 0 0 40%;
  }
}
section .icon-box > div figure {
  border: 1px solid #006db3;
  border-radius: 50%;
  margin-bottom: 10px;
}
section .icon-box > div figure.no-circle {
  border: 0;
}
section .icon-box > div figure.no-circle img {
  padding: 0;
}
section .icon-box > div figure img {
  padding: 30px;
  width: 130px;
  height: 130px;
}
@media (max-width: 600px) {
  section .icon-box > div figure img {
    width: 90px;
    height: 90px;
    padding: 20px;
  }
}
section .icon-box > div .subtitle {
  font-size: 35px;
  color: #006db3;
  font-weight: bold;
  margin-bottom: 0;
}
@media (max-width: 600px) {
  section .icon-box > div .subtitle {
    font-size: 28px;
    margin-bottom: 6px;
  }
}
section .icon-box > div .sub-subtitle {
  font-size: 18px;
  color: #006db3;
  text-align: center;
  font-weight: 600;
}
@media (max-width: 600px) {
  section .icon-box > div .sub-subtitle {
    font-size: 18px;
    text-align: center;
  }
}
section.offer {
  text-align: center;
}
section .btn-orange {
  margin: 0 auto;
  border: 1px solid #ff840c;
  border-radius: 50px;
  margin-bottom: 20px;
  color: #ff840c !important;
  padding: 10px 20px;
}
section .nav-tabs {
  justify-content: space-evenly;
  border: 0;
  margin-bottom: 40px;
}
@media (max-width: 600px) {
  section .nav-tabs {
    margin-bottom: 20px;
  }
}
section .nav-tabs.cont-button {
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 600px) {
  section .nav-tabs.cont-button {
    flex-wrap: nowrap;
    overflow: auto;
    justify-content: flex-start;
  }
}
section .nav-tabs.cont-button ::-webkit-scrollbar {
  height: 20px;
}
section .nav-tabs.cont-button ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}
section .nav-tabs.cont-button ::-webkit-scrollbar-thumb {
  background: red;
  border-radius: 10px;
}
section .nav-tabs.cont-button ::-webkit-scrollbar-thumb:hover {
  background: #b30000;
}
section .nav-tabs.cont-button .nav-item {
  flex: 0 0 33.3%;
  margin: 0;
  padding: 0 30px;
  text-align: center;
  margin-bottom: 30px;
}
@media (max-width: 600px) {
  section .nav-tabs.cont-button .nav-item {
    margin-bottom: 10px;
    padding-left: 0;
    padding-right: 15px;
  }
}
section .nav-tabs.cont-button .nav-item .nav-link {
  width: 270px;
  height: 55px;
  border-radius: 32px;
  display: flex;
  align-items: center;
  margin: 0 auto;
  justify-content: center;
  font-size: 20px;
  line-height: 1;
  cursor: pointer;
}
@media (max-width: 600px) {
  section .nav-tabs.cont-button .nav-item .nav-link {
    font-size: 16px;
    width: 140px;
    height: 45px;
    margin-bottom: 15px;
  }
}
section .nav-tabs .nav-item {
  margin: 0 10px;
}
section .nav-tabs .nav-item .nav-link {
  border: 1px solid #025079;
  border-radius: 28px;
  font-size: 22px;
  color: #025079;
  height: 55px;
  width: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 600px) {
  section .nav-tabs .nav-item .nav-link {
    font-size: 20px;
    width: 200px;
    height: 45px;
    margin-bottom: 15px;
  }
}
section .nav-tabs .nav-item .nav-link.active {
  background-color: #006db3;
  color: #fff;
  border: 0;
}
section .cont-img {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-image: url(../img/EQUALIZER.svg);
  background-position: center;
}
section .cont-img .box-item {
  flex: 0 0 25%;
  overflow: hidden;
}
@media (max-width: 600px) {
  section .cont-img .box-item {
    flex: 0 0 50%;
  }
}
section .cont-img .box-item img {
  max-width: 100%;
  transition: all 0.3s;
  display: block;
  width: 100%;
  height: auto;
  transform: scale(1);
}
section .cont-img .box-item img:hover {
  transform: scale(1.2);
}
section .our-strengths {
  list-style: none;
  text-align: center;
  line-height: 2;
  padding-inline-start: 0;
}
@media (max-width: 600px) {
  section .our-strengths {
    padding: 0;
  }
}
section .our-strengths li {
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
}
@media (max-width: 600px) {
  section .our-strengths li {
    font-size: 17px;
    line-height: 1.4;
    margin-bottom: 15px;
  }
}
section .download-txt {
  font-size: 22px;
  font-weight: 500;
  text-align: center;
  width: 100%;
  color: #000;
}
@media (max-width: 600px) {
  section .download-txt {
    font-size: 18px;
  }
}
section .title-download {
  font-weight: 300;
  color: #025079;
  font-size: 22px;
  text-transform: uppercase;
  text-align: center;
}
section .download-list {
  display: flex;
  list-style: none;
  justify-content: center;
  flex-wrap: wrap;
  padding-inline-start: 0;
}
@media (max-width: 600px) {
  section .download-list {
    padding: 0;
    flex-direction: column;
  }
}
section .download-list li {
  margin: 0 15px;
}
@media (max-width: 600px) {
  section .download-list li {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }
}
section .download-list li a {
  font-size: 17px;
  text-transform: uppercase;
  font-weight: 300;
  color: #000;
  text-decoration: underline;
}
section.contactus-section .contactbox {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  text-align: center;
}
section.contactus-section .contactbox .country {
  font-size: 20px;
  font-weight: 600;
}
@media (max-width: 600px) {
  section.contactus-section .contactbox .country {
    font-size: 18px;
  }
}
section.contactus-section .contactbox .name-society {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 0;
}
@media (max-width: 600px) {
  section.contactus-section .contactbox .name-society {
    font-size: 20px;
  }
}
section.contactus-section .contactbox p {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 0;
}
section.contactus-section .box-cont {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
}
section.contactus-section .box-cont .contactbox {
  flex: 50%;
  margin-bottom: 20px;
}
section.contactus-section .box-cont .contactbox .country {
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
}
section.contactus-section .box-cont .contactbox .name-society {
  font-size: 20px;
  font-weight: 600;
}
section.newsletter-section .cont-form {
  display: flex;
  flex-wrap: wrap;
}
section.newsletter-section .cont-form .left-part {
  flex: 60%;
  padding: 0 40px;
  border-right: 1px solid #2280bd;
}
@media (max-width: 600px) {
  section.newsletter-section .cont-form .left-part {
    border: 0;
    padding: 0;
  }
}
section.newsletter-section .cont-form .left-part .title-sub {
  font-size: 24px;
  color: #000;
  margin-bottom: 25px;
}
section.newsletter-section .cont-form .left-part form ::placeholder {
  color: #2280bd;
  opacity: 1; /* Firefox */
}
section.newsletter-section .cont-form .left-part form .form-group .form-control {
  border-radius: 18px;
  color: #2280bd;
  border: 1px solid #2280bd;
}
section.newsletter-section .cont-form .left-part form .form-group button {
  background-color: #2280bd;
  color: #fff !important;
  text-align: left;
}
@media (max-width: 600px) {
  section.newsletter-section .cont-form .left-part form .form-group button {
    text-align: center;
  }
}
@media (max-width: 600px) {
  section.newsletter-section .cont-form .left-part form .form-group .col-md-6:first-child input,
section.newsletter-section .cont-form .left-part form .form-group .col-md-6:first-child select {
    margin-bottom: 1rem;
  }
}
section.newsletter-section .cont-form .right-part {
  flex: 40%;
  padding: 0 40px;
  position: relative;
}
@media screen and (max-width: 600px) {
  section.newsletter-section .cont-form .right-part {
    flex: 100%;
    padding: 0 20px;
  }
}
section.newsletter-section .cont-form .right-part .circle-dwnl {
  border: 1px solid #2280bd;
  border-radius: 50%;
  width: 50px;
  display: flex;
  height: 50px;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 103px;
  right: 113px;
}
@media (max-width: 350px) {
  section.newsletter-section .cont-form .right-part .circle-dwnl {
    bottom: 100px;
    right: 30px;
    height: 60px;
    width: 60px;
  }
}
@media (min-width: 350px) and (max-width: 420px) {
  section.newsletter-section .cont-form .right-part .circle-dwnl {
    bottom: 90px;
    right: 60px;
    width: 70px;
    height: 70px;
  }
}
section.newsletter-section .g-recaptcha {
  position: relative !important;
}
section.newsletter-section p.txt-privacy {
  font-size: 13px;
  margin-bottom: 0;
}
section.newsletter-section #privacy_check {
  margin-top: 5px;
}
section.newsletter-section .submit-btn {
  width: auto;
  margin: 0 auto;
  padding: 1px 30px;
}
section.social-section {
  background-color: #f4f4f4;
  padding: 40px 0 60px;
}
section.social-section .container {
  display: flex;
  flex-flow: column;
}
section.social-section .container .title-section {
  margin-bottom: 30px;
}
section.banner-image {
  padding-top: 50px;
  padding-bottom: 20px;
  margin: 45px 0 30px;
}
section.banner-image .container {
  max-width: 400px;
}
@media (min-width: 1200px) {
  section.banner-image .container {
    max-width: 780px !important;
  }
}
section.banner-image .title-section {
  color: #fff;
  font-size: 24px;
  font-weight: 500;
}
section.banner-image .text-section {
  font-size: 21px;
  font-weight: 400;
  color: #fff;
}
section.banner-image .text-section a {
  color: #fff;
  font-weight: 500;
}

.anchor {
  display: block;
  height: 93px;
  margin-top: -93px;
  visibility: hidden;
}

.anchor2 {
  display: block;
  height: 93px;
  margin-top: -93px;
  visibility: hidden;
}

.image-banner {
  width: 100%;
  height: 400px;
  top: 0;
  left: 0;
  z-index: -1;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: top center;
  overflow: hidden;
  margin: 0 0 30px;
}
@media (max-width: 600px) {
  .image-banner {
    height: 200px;
  }
}
.image-banner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#map {
  width: 100%;
}
#map iframe {
  display: block;
  width: 100%;
  max-width: 100%;
  height: 20em;
  margin: 2rem auto;
  border: 1px solid black;
}

.load-more {
  color: #2280bd;
  padding: 6px 12px;
  border-radius: 20px;
  font-size: 20px;
  margin: 50px auto;
  display: flex;
  width: fit-content;
  border: 1px solid #2280bd;
}
@media (max-width: 600px) {
  .load-more {
    margin-bottom: 10px;
  }
}

.load-more:hover {
  background-color: #2280bd;
  text-decoration: none;
  color: #fff;
}

.mobile-on {
  display: block;
}
@media (min-width: 500px) {
  .mobile-on {
    display: none;
  }
}

.desktop-on {
  display: none;
}
@media (min-width: 500px) {
  .desktop-on {
    display: block;
  }
}

.btn-section {
  text-align: center;
}
@media (max-width: 600px) {
  .btn-section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.btn-section .btn-orange {
  margin: 0 10px;
  width: fit-content;
  min-width: 200px;
  margin-bottom: 10px;
}

.carousel-indicators {
  position: absolute;
  right: 0;
  top: 445px;
  height: 25px;
}
@media screen and (max-width: 600px) {
  .carousel-indicators {
    top: 254px;
  }
}
.carousel-indicators li {
  width: 10px !important;
  height: 10px !important;
  border-radius: 50%;
  background-color: grey !important;
}
.carousel-indicators li.active {
  background-color: #ff840c !important;
}

.banner {
  width: 100%;
  height: 100vh;
  padding: 0;
}
.banner::before {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(rgb(21, 21, 60), transparent, transparent, transparent);
  z-index: 10;
  content: " ";
  position: absolute;
  top: 0;
}
.banner .title-banner {
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  font-size: 44px;
  font-weight: normal;
}
@media screen and (max-width: 600px) {
  .banner .title-banner {
    font-size: 25px;
  }
}
.banner.video {
  background: #202857;
  overflow: hidden;
  display: block;
  padding: 0;
  position: relative;
  background-image: linear-gradient(rgb(21, 21, 60), transparent, transparent, transparent);
}
@media screen and (max-width: 499px) {
  .banner.video {
    height: 0;
    padding-top: 128%;
    width: 100%;
  }
}
.banner.video .btn-vol {
  display: none;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 1px solid #fff;
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  z-index: 1000;
  font-size: 0;
  background-size: 60%;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(../img/audio-off.svg);
  opacity: 0.5;
  cursor: pointer;
  transition: opacity 0.15s ease-in-out;
}
.banner.video .btn-vol:hover {
  opacity: 1;
}
.banner.video .btn-vol.unmute {
  background-image: url(../img/audio-on.svg);
}
.banner.video .text-content {
  z-index: 3;
}
.banner.video .video-background {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: 100%;
  z-index: 1;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  opacity: 1;
}
@media (min-aspect-ratio: 16/9) {
  .banner.video .video-background {
    width: 100%;
    height: auto;
  }
}
.banner.video .video-background.mobile-on {
  width: 100%;
}
.banner.video .btn-down {
  background-color: white;
  position: absolute;
  z-index: 100;
  left: 50%;
  transform: translateX(-50%);
  top: 92vh;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: bounce2 2s ease infinite;
  box-shadow: 0.5px 0.5px 0.5px #333;
  color: #202857;
  font-size: 25px;
  text-decoration: none;
}
@media screen and (max-width: 500px) {
  .banner.video .btn-down {
    display: none;
  }
}
@keyframes bounce2 {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}
.banner picture source, .banner picture img {
  width: 100%;
  height: 100%;
}

.slider-section {
  margin-bottom: 30px;
}
.slider-section .carousel-item .image {
  background: #202857;
  height: 440px;
  overflow: hidden;
  object-fit: cover;
}
@media screen and (max-width: 600px) {
  .slider-section .carousel-item .image {
    height: 250px;
  }
}
.slider-section .carousel-item .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 1;
}
.slider-section .slick-dots li {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: grey;
}
.slider-section .slick-dots li.slick-active {
  background-color: #ff840c;
}
.slider-section .slick-dots li button::before {
  font-size: 0;
}

.text-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

h1 {
  color: white;
  font-family: "Nobile", sans-serif;
  font-size: 9vw;
  text-align: center;
}

strong {
  font-weight: bold !important;
}

.orange-section {
  background-color: #ff9228;
}
.orange-section .text-section {
  color: #fff;
}
.orange-section .title-section {
  color: #fff;
}
.orange-section .dot-list {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 600px) {
  .orange-section .dot-list {
    flex-wrap: wrap;
  }
}
.orange-section .dot-list .list {
  flex: 1;
  position: relative;
  margin-left: -2px;
}
@media screen and (max-width: 800px) {
  .orange-section .dot-list .list {
    flex: 100%;
  }
}
.orange-section .dot-list .list p.number {
  text-align: center;
  color: #fff;
  font-weight: bold;
  font-size: 21px;
}
.orange-section .dot-list .list.start {
  margin-bottom: 0;
  position: relative;
  max-width: 250px;
}
@media screen and (max-width: 900px) {
  .orange-section .dot-list .list.start {
    max-width: 150px;
    margin: 0 auto;
    height: 160px;
  }
}
.orange-section .dot-list .list.start::before {
  display: none;
}
.orange-section .dot-list .list.start::after {
  display: none;
}
.orange-section .dot-list .list .line {
  position: relative;
  display: flex;
  justify-content: center;
  height: 60px;
  margin-top: 30px;
}
@media screen and (max-width: 900px) {
  .orange-section .dot-list .list .line {
    margin-top: 0;
    height: 50px;
  }
}
.orange-section .dot-list .list .line::after {
  content: "";
  width: 15px;
  height: 15px;
  background-color: #fff;
  border-radius: 100%;
  position: absolute;
  left: 50%;
  bottom: -4px;
  transform: translate(-50%, 0);
}
@media screen and (min-width: 600px) and (max-width: 1000px) {
  .orange-section .dot-list .list .line::after {
    bottom: -8px !important;
  }
}
.orange-section .dot-list .list .line::before {
  content: "";
  width: 100%;
  height: 2px;
  position: absolute;
  background-color: #fff;
  border-radius: 5px;
  left: 49%;
  bottom: 0;
  transform: translate(-50%, -50%);
}
@media screen and (min-width: 600px) and (max-width: 1000px) {
  .orange-section .dot-list .list .line::before {
    height: 2px;
    bottom: -3px !important;
  }
}
.orange-section .dot-list .list p.descrizione {
  color: #fff;
  text-align: center;
  margin-top: 30px;
  font-size: 20px;
}
@media screen and (max-width: 600px) {
  .orange-section .dot-list .list p.descrizione {
    margin-top: 10px;
  }
}
.orange-section .dot-list .list p.descrizione.absolute {
  position: absolute;
  bottom: 38px;
  left: 46%;
  transform: translate(-50%, -50%);
}
@media screen and (min-width: 600px) and (max-width: 1100px) {
  .orange-section .dot-list .list p.descrizione.absolute {
    white-space: nowrap;
    bottom: -12px;
  }
}
@media screen and (min-width: 1000px) and (max-width: 1200px) {
  .orange-section .dot-list .list p.descrizione.absolute {
    bottom: -6px;
  }
}
@media screen and (max-width: 600px) {
  .orange-section .dot-list .list p.descrizione.absolute {
    position: relative;
    bottom: 47px;
    left: 0;
    transform: none;
    margin-top: 0;
  }
}
.orange-section .dot-list.dark {
  flex: 0 0 80%;
}
@media screen and (max-width: 900px) {
  .orange-section .dot-list.dark {
    flex: 100%;
  }
}
.orange-section .dot-list.dark .list {
  margin-left: -5px;
}
.orange-section .dot-list.dark .list.start {
  margin: 0 auto;
}
.orange-section .dot-list.dark p.descrizione {
  color: #ff840c;
}
@media screen and (max-width: 900px) {
  .orange-section .dot-list.dark p.descrizione {
    margin-top: 10px;
  }
}
.orange-section .dot-list.dark p.descrizione.absolute {
  white-space: nowrap;
  bottom: 13px;
}
@media screen and (max-width: 600px) {
  .orange-section .dot-list.dark p.descrizione.absolute {
    bottom: 43px;
  }
}
@media screen and (max-width: 600px) {
  .orange-section .dot-list.dark .line {
    height: 50px;
  }
}
.orange-section .dot-list.dark .line::after {
  background-color: #ff840c;
  bottom: -4px;
}
.orange-section .dot-list.dark .line::before {
  background-color: #ff840c;
  bottom: 0.5px;
}
.orange-section .dot-list.dark p.number {
  color: #ff840c;
}
.orange-section .dot-list.dark .filter-orange {
  filter: invert(31%) sepia(38%) saturate(7048%) hue-rotate(353deg) brightness(103%) contrast(103%);
}
.orange-section .list-text {
  display: flex;
}
.orange-section .list-text span {
  flex: 1;
  text-align: center;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
}

.slick-slide a {
  display: block !important;
}

.offer .header, .radio .header {
  background-image: linear-gradient(#15153c, transparent);
}

.content-page {
  padding-top: 10px;
}
.content-page .img-part img {
  width: 100px;
  margin-bottom: 10px;
}
.content-page .name-logo {
  margin: 30px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.content-page .name-logo .title-page {
  font-size: 24px;
  text-transform: uppercase;
  color: #FF840C;
  margin-bottom: 0;
  font-weight: 400;
}
@media screen and (max-width: 600px) {
  .content-page .name-logo .title-page {
    font-size: 24px;
  }
}
.content-page .name-logo .nome-radio {
  color: #FF840C;
  text-transform: uppercase;
  font-size: 32px;
  margin-bottom: 0;
}
.content-page .name-logo .back-button {
  border: 1px solid #b1b3b4;
  color: #b1b3b4;
  border-radius: 24px;
  padding: 5px 35px;
  height: 50px;
  text-transform: uppercase;
  font-weight: normal;
}
@media screen and (max-width: 600px) {
  .content-page .name-logo .back-button {
    padding: 2px 25px;
    font-size: 12px;
  }
}
.content-page img.logo {
  width: auto;
  height: auto;
  margin: 5px 0;
}
@media screen and (max-width: 600px) {
  .content-page img.logo {
    margin-bottom: 30px;
  }
  .content-page img.logo img {
    height: 56px;
  }
}
.content-page p.text {
  font-size: 19px;
  margin-bottom: 10px;
}
@media screen and (max-width: 600px) {
  .content-page p.text {
    font-size: 18px;
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 600px) {
  .content-page .icon-box {
    align-items: baseline;
  }
}
.content-page .icon-box .piksel-wysiwyg-litem {
  flex: 0 0 25%;
  align-items: baseline;
}
@media screen and (max-width: 600px) {
  .content-page .icon-box .piksel-wysiwyg-litem {
    flex: 0 0 50%;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}
.content-page .icon-box .piksel-wysiwyg-litem figure {
  margin-bottom: 10px;
}
.content-page .icon-box .piksel-wysiwyg-litem figure img {
  width: 80px;
  height: 80px;
  padding: 15px;
}
.content-page .icon-box .piksel-wysiwyg-litem .subtitle {
  font-size: 34px;
  font-weight: 500;
  margin-bottom: 10px;
}
.content-page .icon-box .piksel-wysiwyg-litem .sub-subtitle {
  font-size: 16px;
  padding-right: 10px;
  text-align: left;
}
@media screen and (max-width: 600px) {
  .content-page .icon-box .piksel-wysiwyg-litem .sub-subtitle {
    text-align: center;
    padding-right: 0;
  }
}
.content-page .icon-box .piksel-wysiwyg-litem .digitalia-img {
  width: 80px;
  height: auto;
  margin-bottom: 15px;
}
.content-page .dwnl-link {
  text-transform: uppercase;
  text-decoration: underline;
  font-size: 16px;
  color: #000;
}
@media screen and (max-width: 600px) {
  .content-page .dwnl-link {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.radio .orange-section {
  background-color: transparent;
}
.radio .orange-section.row-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media screen and (max-width: 1000px) {
  .radio .orange-section.row-flex {
    flex-direction: column;
    align-items: normal;
  }
}
.radio .orange-section.row-flex .text {
  flex: 0 0 20%;
  text-align: right;
}
@media screen and (max-width: 1000px) {
  .radio .orange-section.row-flex .text {
    flex: 100%;
    text-align: center;
  }
}
.radio .orange-section.row-flex .text a {
  color: grey;
  border-radius: 20px;
}

.video-overlay {
  position: fixed;
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
}
.video-overlay iframe {
  width: calc(100% - 15rem);
  height: calc(100% - 15rem);
}
@media screen and (max-width: 800px) {
  .video-overlay iframe {
    width: calc(100% - 1rem);
    height: fit-content;
  }
}
.video-overlay .box-close {
  position: absolute;
  right: 2rem;
  top: 2rem;
  cursor: pointer;
  color: #000;
  background-color: #fff;
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 999px;
  font-size: 20px;
  user-select: none;
  transform: scale(1);
  transition: 0.25s all ease;
}
.video-overlay .box-close:hover {
  transform: scale(1.2);
}

.offer-p .intestazione-offerta {
  text-align: center;
}
.offer-p .intestazione-offerta h2 {
  margin-bottom: 5px;
}
.offer-p .intestazione-offerta p {
  font-size: 20px;
  font-weight: 500;
}
.offer-p .dwnl {
  padding: 25px 0;
}
.offer-p .dwnl .dwnl-row {
  display: flex;
  align-items: center;
}
.offer-p .dwnl .dwnl-row .list-downloads {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  flex: 65%;
  justify-content: space-evenly;
}
.offer-p .dwnl .dwnl-row .list-downloads .icon-download-pdf {
  background-image: url(/assets/img/pdf_icon.png);
  width: 62px;
  height: 55px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 10px;
}
.offer-p .dwnl .dwnl-row .list-downloads a {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.offer-p .dwnl .dwnl-row .list-downloads a .label {
  color: #000;
}
.offer-p .dwnl .dwnl-row .list-downloads li {
  flex: 1;
}
.offer-p .dwnl .dwnl-row .download-label {
  flex: 35%;
  font-weight: 500;
  font-size: 22px;
}
@media (min-width: 1200px) {
  .offer-p .dwnl .dwnl-row.container {
    max-width: 780px !important;
  }
}
.offer-p .dwnl.grey {
  background-color: #eceeee;
}

.hero-fullwidth {
  width: 100%;
}
.hero-fullwidth img {
  width: 100%;
}

.start.article .header {
  background-color: #ff840c;
}
.start.article section {
  padding-top: 125px;
}
.start.article section .paragraph {
  margin-bottom: 45px;
}
.start.article section .title-article {
  font-size: 38px;
  font-weight: bold;
  color: #ff840c;
  margin-bottom: 20px;
}
.start.article section .title-section {
  text-align: left;
  font-size: 26px;
  color: black;
  text-transform: none;
  font-weight: 600;
}
.start.article section .text-section {
  text-align: left;
  padding: 0;
}
.start.article .img__right {
  float: right;
  margin-left: 20px;
}
.start.article .img__left {
  float: left;
  margin-right: 20px;
}
.start.article .img__center {
  margin: 20px auto;
  text-align: center;
}

.footer {
  background-color: #eee;
  padding: 50px 0;
  margin-top: 30px;
}
@media screen and (max-width: 600px) {
  .footer {
    padding: 25px 0;
  }
}
.footer .logo-footer {
  margin-bottom: 20px;
}
@media screen and (max-width: 600px) {
  .footer .logo-footer {
    margin: 0 auto;
    margin-bottom: 15px;
  }
}
.footer .logo-footer img {
  max-width: 100%;
  width: 80%;
  margin-bottom: 15px;
}
@media screen and (max-width: 600px) {
  .footer .logo-footer img {
    max-width: 60%;
    margin: 0 auto;
    margin-bottom: 10px;
    display: flex;
  }
}
@media screen and (max-width: 600px) {
  .footer .content-footer {
    padding: 0 20px;
  }
}
.footer .content-footer .row-menu {
  border-right: 1px solid #707070;
  flex: 0 0 17%;
}
@media screen and (max-width: 600px) {
  .footer .content-footer .row-menu {
    flex: 0 0 100%;
    border-right: 0;
  }
}
@media screen and (max-width: 600px) {
  .footer .content-footer .row-menu .navbar-nav {
    flex: 0 0 100%;
    border-right: 0;
    text-align: center;
    margin-bottom: 40px;
  }
}
.footer .content-footer .row-menu .navbar-nav:last-child {
  flex: 0 0 28%;
}
@media screen and (max-width: 600px) {
  .footer .content-footer .row-menu .navbar-nav:last-child {
    flex: 0 0 100%;
    border-right: 0;
    text-align: center;
    margin-bottom: 40px;
  }
}
.footer .content-footer .row-menu .navbar-nav .nav-item .nav-link {
  color: #707070;
  text-transform: uppercase;
  padding: 0.2rem 1rem;
}
.footer .content-footer .contact {
  padding: 0 40px;
  border-right: 1px solid #707070;
  flex: 36%;
  display: flex;
  flex-direction: column;
  color: #707070;
}
.footer .content-footer .contact.big {
  flex: 42%;
  border-right: 0;
}
.footer .content-footer .contact.big p a {
  text-transform: uppercase;
  color: #707070;
}
@media screen and (max-width: 600px) {
  .footer .content-footer .contact {
    flex: 0 0 100%;
    padding: 0;
    border-right: 0;
    text-align: center;
  }
}
.footer .content-footer .contact .society-name {
  font-size: 20px;
  font-weight: 400;
}
@media screen and (max-width: 600px) {
  .footer .content-footer .contact .society-name {
    font-size: 18px;
  }
}
.footer .content-footer .contact .txt-footer {
  font-size: 18px;
}
.footer .content-footer .contact .txt-footer a {
  color: #707070;
  text-decoration: none;
  font-weight: 600;
}
.footer .content-footer .contact .txt-footer a:link {
  color: #707070;
}
.footer .content-footer .contact .ul-list {
  margin-top: 10px;
  display: flex;
  list-style: none;
  padding: 0;
}
@media screen and (max-width: 600px) {
  .footer .content-footer .contact .ul-list {
    justify-content: center;
  }
}
.footer .content-footer .contact .ul-list li {
  white-space: nowrap;
}
.footer .content-footer .contact .ul-list li p {
  margin-bottom: 0;
}
.footer .content-footer .contact .ul-list li a {
  font-size: 16px;
  color: #707070;
}
.footer .content-footer .contact .ul-list li a::after {
  content: "|";
  margin-left: 5px;
  margin-right: 5px;
}
.footer .content-footer .contact .ul-list li:last-child a::after {
  display: none;
}
.footer .content-footer .contact a {
  color: #707070;
  font-weight: 500;
}