.start{
    &.article{
        .header{
            background-color: #ff840c;
        }
        section{
           padding-top: 125px;
           .paragraph{
            margin-bottom: 45px;
           }
           .title-article{
               font-size: 38px;
               font-weight: bold;
               color: #ff840c;
               margin-bottom: 20px;
           }
           .title-section{
               text-align: left;
               font-size: 26px;
               color: black;
               text-transform: none;
               font-weight: 600;
           }
           .text-section{
               text-align: left;
               padding: 0;
           }
        }
        .img{
            &__right{
                float: right;
                margin-left: 20px;
            }
            &__left{
                float: left;
                margin-right: 20px;
            }
            &__center{
              margin: 20px auto;
              text-align: center;
            }
        }
    }
}