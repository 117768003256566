.container {
    @media (min-width: 1200px) {
      max-width: 1024px !important;
    }
  }
  .header {
    &.scrolled {
      background-image: none !important;
      background-color: #FF840C;
    }
    &.full-height {
      height: 100vh;
      background-color: rgba(255, 132, 12, 0.95);
      background-image: none !important;
    }
    .navbar {
      padding: 25px !important;
      max-width: 100% !important;
      @media (min-width: 1074px) {
        padding: 25px 0 !important;
        max-width: 1024px !important;
      }
      @media (min-width: 1221px) {
        flex-flow: row;
        .collapse {
          display: flex !important;
        }
      }
      .logo-wrap {
        @media (max-width: 1220px) {
        width: 100%;
        display: flex;
        justify-content: space-between;
        }
      }
      .show {
        .navbar-nav {
          display: block;
        }
      }
      .navbar-nav {
        width: 100%;
        justify-content: space-evenly;
        flex-direction: column !important;
        @media (max-width: 1220px) {
          margin-top: 20px;
        }
        @media (min-width: 1221px) {
          flex-direction: row !important;
          display: flex !important;
        }
        .nav-item {
          &.active {
            .nav-link {
              font-weight: bold;
            }
          }
          a.nav-link {
            color: #fff;
            text-transform: uppercase;
            font-size: 17px;
          }
  
        }
      }
      .navbar-brand {
        @media (max-width: 1220px) {
          width: 190px;
        }
        img {
          max-width: 75%;
        }
      }
    }
  .navbar .navbar-toggler {
    @media (min-width: 1221px) {
      display: none !important;
    }
    @media (max-width: 1220px) {
      display: block !important;
    }
    }
  
  }
  
  .generic_page {
    padding-top: 116px;
    .header {
      background-image: none !important;
      background-color: #1769b2;
    }
  }
  