body {
    font-family: 'Montserrat', sans-serif !important;
    &.menu-opened {
      overflow: hidden;
    }
  }
  section {
    padding: 40px 0 30px 0;
    &.slider-section {
      padding: 0;
    }
    &.bg-grey {
      background-color: #f3f3f3;
    }
    .title-section {
      color: #ff840c;
      font-size: 34px;
      text-transform: uppercase;
      font-weight: 500;
      text-align: center;
      margin-bottom: 20px;
      @media (max-width: 600px) {
        font-size: 23px;
      }
    }
    .text-section {
      font-size: 19px;
      font-weight: 300;
      color: #000;
      text-align: center;
      margin-bottom: 30px;
      padding: 0 20px;
      @media (max-width: 600px) {
        padding: 0;
        font-size: 18px;
      }
    }
    .icon-box {
      display: flex;
      flex-wrap: wrap;
      @media (max-width: 600px) {
        justify-content: space-around;
      }
      > div {
        flex: 0 0 33.3%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 30px;
        line-height: 1;
        @media (max-width: 600px) {
          flex: 0 0 40%;
        }
        figure {
          border: 1px solid #006db3;
          border-radius: 50%;
          margin-bottom: 10px;
          &.no-circle {
            border: 0;
            img {
              padding: 0;
            }
          }
          img {
            padding: 30px;
            width: 130px;
            height: 130px;
            @media (max-width: 600px) {
              width: 90px;
              height: 90px;
              padding: 20px;
            }
          }
        }
        .subtitle {
          font-size: 35px;
          color: #006db3;
          font-weight: bold;
          margin-bottom: 0;
          @media (max-width: 600px) {
            font-size: 28px;
            margin-bottom: 6px;
          }
        }
        .sub-subtitle {
          font-size: 18px;
          color: #006db3;
          text-align: center;
          font-weight: 600;
          @media (max-width: 600px) {
            font-size: 18px;
            text-align: center;
          }
        }
      }
    }
    &.offer{
      text-align: center;
    }
    .btn-orange{
      margin: 0 auto;
      border: 1px solid #ff840c;
      border-radius: 50px;
      margin-bottom: 20px;
      color: #ff840c !important;
      padding: 10px 20px;
    }
    .nav-tabs {
      justify-content: space-evenly;
      border: 0;
      margin-bottom: 40px;
      @media (max-width: 600px) {
        margin-bottom: 20px;
      }
      &.cont-button {
        display: flex;
        flex-wrap: wrap;
        @media (max-width: 600px) {
          flex-wrap: nowrap;
          overflow: auto;
          justify-content: flex-start;
        }
        ::-webkit-scrollbar {
          height: 20px;
        }
        ::-webkit-scrollbar-track {
          box-shadow: inset 0 0 5px grey;
          border-radius: 10px;
        }
        ::-webkit-scrollbar-thumb {
          background: red;
          border-radius: 10px;
        }
        ::-webkit-scrollbar-thumb:hover {
          background: #b30000;
        }
        .nav-item {
          flex: 0 0 33.3%;
          margin: 0;
          padding: 0 30px;
          text-align: center;
          margin-bottom: 30px;
          @media (max-width: 600px) {
            margin-bottom: 10px;
            padding-left: 0;
            padding-right: 15px;
          }
          .nav-link {
            width: 270px;
            height: 55px;
            border-radius: 32px;
            display: flex;
            align-items: center;
            margin: 0 auto;
            justify-content: center;
            font-size: 20px;
            line-height: 1;
            cursor: pointer;
            @media (max-width: 600px) {
              font-size: 16px;
              width: 140px;
              height: 45px;
              margin-bottom: 15px;
            }
          }
        }
      }
      .nav-item {
        margin: 0 10px;
        .nav-link {
          border: 1px solid #025079;
          border-radius: 28px;
          font-size: 22px;
          color: #025079;
          height: 55px;
          width: 250px;
          display: flex;
          justify-content: center;
          align-items: center;
          @media (max-width: 600px) {
            font-size: 20px;
            width: 200px;
            height: 45px;
            margin-bottom: 15px;
          }
          &.active {
            background-color: #006db3;
            color: #fff;
            border: 0;
          }
        }
      }
    }
    .cont-img {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      background-image: url(../img/EQUALIZER.svg);
      background-position: center;
      .box-item {
        flex: 0 0 25%;
        overflow: hidden;
        @media (max-width: 600px) {
          flex: 0 0 50%;
        }
        img {
          max-width: 100%;
          transition: all 0.3s;
          display: block;
          width: 100%;
          height: auto;
          transform: scale(1);
          &:hover {
            transform: scale(1.2);
          }
        }
      }
    }
    .our-strengths {
      list-style: none;
      text-align: center;
      line-height: 2;
      padding-inline-start: 0;
      @media (max-width: 600px) {
        padding: 0;
      }
      li {
        font-size: 20px;
        font-weight: bold;
        text-transform: uppercase;
        @media (max-width: 600px) {
          font-size: 17px;
          line-height: 1.4;
          margin-bottom: 15px;
        }
      }
    }
    .download-txt {
      font-size: 22px;
      font-weight: 500;
      text-align: center;
      width: 100%;
      color: #000;
      @media (max-width: 600px) {
        font-size: 18px;
      }
    }
    .title-download {
      font-weight: 300;
      color: #025079;
      font-size: 22px;
      text-transform: uppercase;
      text-align: center;
    }
    .download-list {
      display: flex;
      list-style: none;
      justify-content: center;
      flex-wrap: wrap;
      padding-inline-start: 0;
      @media (max-width: 600px) {
        padding: 0;
        flex-direction: column;
      }
      li {
        margin: 0 15px;
        @media (max-width: 600px) {
          width: 100%;
          text-align: center;
          margin-bottom: 10px;
        }
        a {
          font-size: 17px;
          text-transform: uppercase;
          font-weight: 300;
          color: #000;
          text-decoration: underline;
        }
      }
    }
    &.contactus-section {
      .contactbox {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 30px;
        text-align: center;
        .country {
          font-size: 20px;
          font-weight: 600;
          @media (max-width: 600px) {
            font-size: 18px;
          }
        }
        .name-society {
          font-size: 24px;
          font-weight: 500;
          margin-bottom: 0;
          @media (max-width: 600px) {
            font-size: 20px;
          }
        }
        p {
          font-size: 18px;
          font-weight: 400;
          margin-bottom: 0;
        }
      }
      .box-cont {
        display: flex;
        flex-wrap: wrap;
        text-align: center;
        .contactbox {
          flex: 50%;
          margin-bottom: 20px;
  
          .country {
            font-size: 20px;
            font-weight: 600;
            text-transform: uppercase;
          }
          .name-society {
            font-size: 20px;
            font-weight: 600;
          }
        }
      }
    }
    &.newsletter-section {
      .cont-form {
        display: flex;
        flex-wrap: wrap;
        .left-part {
          flex: 60%;
          padding: 0 40px;
          border-right: 1px solid #2280bd;
          @media (max-width: 600px) {
            border: 0;
            padding: 0;
          }
          .title-sub {
            font-size: 24px;
            color: #000;
            margin-bottom: 25px;
          }
          form {
            ::placeholder {
              color: #2280bd;
              opacity: 1; /* Firefox */
            }
            .form-group {
              .form-control {
                border-radius: 18px;
                color: #2280bd;
                border: 1px solid #2280bd;
              }
              button {
                background-color: #2280bd;
                color: #fff !important;
                text-align: left;
                @media (max-width: 600px) {
                  text-align: center;
                }
              }
              .col-md-6:first-child {
                input,
                select {
                  @media (max-width: 600px) {
                    margin-bottom: 1rem;
                  }
                }
              }
            }
          }
        }
        .right-part {
          flex: 40%;
          padding: 0 40px;
          position: relative;
          @media screen and (max-width: 600px) {
            flex: 100%;
            padding: 0 20px;
          }
          .circle-dwnl {
            border: 1px solid #2280bd;
            border-radius: 50%;
            width: 50px;
            display: flex;
            height: 50px;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 103px;
            right: 113px;
            @media (max-width: 350px) {
              bottom: 100px;
              right: 30px;
              height: 60px;
              width: 60px;
            }
            @media (min-width: 350px) and (max-width: 420px) {
              bottom: 90px;
              right: 60px;
              width: 70px;
              height: 70px;
            }
          }
        }
      }
      .g-recaptcha{
        position: relative !important;
      }
      p.txt-privacy{
        font-size: 13px;
        margin-bottom: 0;
      }
      #privacy_check{
        margin-top: 5px;
      }
      .submit-btn{
        width: auto;
        margin: 0 auto;
        padding: 1px 30px;
      }
    }
    &.social-section {
      background-color: #f4f4f4;
      padding: 40px 0 60px;
      .container {
        display: flex;
        flex-flow: column;
        .title-section {
          margin-bottom: 30px;
        }
      }
    }
    &.banner-image{
      padding-top: 50px;
      padding-bottom: 20px;
      margin: 45px 0 30px;
      .container{
        max-width: 400px;
        @media (min-width: 1200px)
    {
    max-width: 780px !important;
    }
      }
      .title-section{
        color: #fff;
        font-size: 24px;
        font-weight: 500;
      }
      .text-section{
        font-size: 21px;
        font-weight: 400;
        color: #fff;
        a{
          color: #fff;
          font-weight: 500;
        }
      }
    }
  }
  .anchor {
    display: block;
    height: 93px;
    margin-top: -93px;
    visibility: hidden;
  }
  .anchor2 {
    display: block;
    height: 93px;
    margin-top: -93px;
    visibility: hidden;
  }
  .image-banner {
    width: 100%;
    height: 400px;
    top: 0;
    left: 0;
    z-index: -1;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: top center;
    overflow: hidden;
    margin: 0 0 30px;
    @media (max-width: 600px) {
      height: 200px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      // @media (max-width: 600px) {
      //   width: 100%;
      // }
    }
  }
  #map {
    width: 100%;
    iframe {
      display: block;
      width: 100%;
      max-width: 100%;
      height: 20em;
      margin: 2rem auto;
      border: 1px solid black;
    }
  }
  
  .load-more {
    color: #2280bd;
    padding: 6px 12px;
    border-radius: 20px;
    font-size: 20px;
    margin: 50px auto;
    display: flex;
    width: fit-content;
    border: 1px solid #2280bd;
    @media (max-width: 600px) {
      margin-bottom: 10px;
    }
  }
  
  .load-more:hover {
    background-color: #2280bd;
    text-decoration: none;
    color: #fff;
  }
  
  .mobile-on {
    display: block;
    @media (min-width: 500px) {
      display: none;
    }
  }
  
  .desktop-on {
    display: none;
    @media (min-width: 500px) {
      display: block;
    }
  }
  .btn-section{
    text-align: center;
    @media (max-width: 600px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .btn-orange{
      margin: 0 10px;
      width: fit-content;
      min-width: 200px;
      margin-bottom: 10px;
    }
  }
  .carousel-indicators {
    position: absolute;
    right: 0;
    top: 445px;
    height: 25px;
    @media screen and (max-width: 600px) {
      top: 254px;
    }
    li{
      width: 10px !important;
      height: 10px !important;
      border-radius: 50%;
      background-color: grey !important;
      &.active{
        background-color: #ff840c !important;
      }
    }
}
