.offer-p{
    .intestazione-offerta{
        text-align: center;
       h2{
        margin-bottom: 5px;
       }
       p{
        font-size: 20px;
        font-weight: 500;
       }
    }
    .dwnl{
        padding: 25px 0;
        .dwnl-row{
            display: flex;
            align-items: center;
            .list-downloads{
                display: flex;
                margin: 0;
                padding: 0;
                list-style: none;
                flex: 65%;
                justify-content: space-evenly;
                .icon-download-pdf{
                    background-image: url(/assets/img/pdf_icon.png);
                    width: 62px;
                    height: 55px;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                    margin-bottom: 10px;
                }
                a{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .label{
                        color: #000;
                    }
                }
                li{
                    flex: 1;
                }
            }
            .download-label{
                flex: 35%;
                font-weight: 500;
                font-size: 22px;
            }
            &.container{
                @media (min-width: 1200px)
                {
                max-width: 780px !important;
                }
            }
        }
        &.grey{
            background-color: #eceeee;
        }
    }
    
}
.hero-fullwidth{
    width: 100%;
    img{
        width: 100%;
    }
}