.footer {
  background-color: #eee;
  padding: 50px 0;
  margin-top: 30px;
  @media screen and (max-width: 600px) {
    padding: 25px 0;
  }
  .logo-footer {
    margin-bottom: 20px;
    @media screen and (max-width: 600px) {
      margin: 0 auto;
      margin-bottom: 15px;
    }
    img {
      max-width: 100%;
      width: 80%;
      margin-bottom: 15px;
      @media screen and (max-width: 600px) {
        max-width: 60%;
        margin: 0 auto;
        margin-bottom: 10px;
        display: flex;
      }
    }
  }
  .content-footer {
    @media screen and (max-width: 600px) {
      padding: 0 20px;
    }
    .row-menu{
      border-right: 1px solid #707070;
      flex: 0 0 17%;
      @media screen and (max-width: 600px) {
        flex: 0 0 100%;
        border-right: 0;
      }
    .navbar-nav {
      @media screen and (max-width: 600px) {
        flex: 0 0 100%;
        border-right: 0;
        text-align: center;
        margin-bottom: 40px;
      }
      &:last-child{
        flex: 0 0 28%;
        @media screen and (max-width: 600px) {
          flex: 0 0 100%;
          border-right: 0;
          text-align: center;
          margin-bottom: 40px;
        }
      }
      .nav-item {
        .nav-link {
          color: #707070;
          text-transform: uppercase;
          padding: 0.2rem 1rem;
        }
      }
    }
  }
    .contact {
      padding: 0 40px;
      border-right: 1px solid #707070;
      flex: 36%;
      display: flex;
      flex-direction: column;
      color: #707070;
      &.big{
        flex: 42%;
        border-right: 0;
        p{
          a{
            text-transform: uppercase;
            color: #707070;
          }
        }
      }
      @media screen and (max-width: 600px) {
        flex: 0 0 100%;
        padding: 0;
        border-right: 0;
        text-align: center;
      }
      .society-name {
        font-size: 20px;
        font-weight: 400;
        @media screen and (max-width: 600px) {
          font-size: 18px;
        }
      }
      .txt-footer {
        font-size: 18px;
        a {
          color: #707070;
          text-decoration: none;
          font-weight: 600;
          &:link {
            color:#707070;
          }
        }
      }
      .ul-list{
        margin-top: 10px;
        display: flex;
        list-style: none;
        padding: 0;
        @media screen and (max-width: 600px) {
          justify-content: center;
        }
        li{
          white-space: nowrap;
          p{
            margin-bottom: 0;
          }
          a{
            font-size: 16px;
            color: #707070;
            &::after{
              content: "|";
              margin-left: 5px;
              margin-right: 5px;
            }
          }
          &:last-child{
            a{
              &::after{
                display: none;
              }
            }
          }
        }
      }
      a{
        color: #707070;
        font-weight: 500;
      }
    }
  }
}
